import { createRouter, createWebHistory } from 'vue-router';
import WelcomePage from '@/components/WelcomePage';
import HomeView from '@/views/HomeView.vue';
import PromptComponent from '@/components/PromptComponent.vue';
import ContenedorViajes from '@/components/ContenedorViajes.vue';
import PromptComponent_BackUP from '@/components/PromptComponent_BackUP.vue';
import FaceScan from '@/components/FaceScan.vue'; // Importa el componente FaceScan
import QRScan from '@/components/QRScan.vue';
import MetaVerso from '@/components/MetaVerso.vue';
import ChatTriunfa from '@/components/ChatTriunfa.vue';
import Conversations from '@/components/ConversationsDatabase.vue'; // Importa el componente
import PanelVisionario from '@/components/PanelVisionario.vue'; // Importa el componente
import ConversationsDashboard from '@/components/ConversationsDashboard.vue'; // <--- Importa el componente nuevo
import LeadsList from '@/components/LeadsList.vue'; // <--- Importa el componente nuevo
import ReplicaInterface from '@/components/ReplicaInterface.vue'; // <--- Importa el componente nuevo


const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: WelcomePage
  },
  {
    path: '/chat', // Nueva ruta para ChatTriunfa
    name: 'ChatTriunfa',
    component: ChatTriunfa
  },
  {
    path: '/leads', // Nueva ruta para ChatTriunfa
    name: 'LeadsList',
    component: LeadsList
  },

  {
    path: '/dashboard', // <--- Nueva ruta para ConversationsDashboard
    name: 'ConversationsDashboard',
    component: ConversationsDashboard
  },


  {
    path: '/conversations',
    name: 'ConversationsDatabase',
    component: Conversations
  },
  {
    path: '/replica',
    name: 'ReplicaInterface',
    component: ReplicaInterface
  },


  {
    path: '/panel',
    name: 'PanelVisionario',
    component: PanelVisionario
  },

  {
    path: '/home',
    name: 'Home',
    component: HomeView
  },

  {
    path: '/metaverso',
    name: 'Metaverso',
    component: MetaVerso
  },

  {
    path: '/contacto',
    name: 'Contacto',
    component: PromptComponent
  },
  {
    path: '/test',
    name: 'Test',
    component: PromptComponent_BackUP
  },
  {
    path: '/viajes/:id',
    name: 'DetalleViaje',
    component: ContenedorViajes,
    props: true
  },
  {
    path: '/FaceScan', // La nueva ruta para el componente FaceScan
    name: 'FaceScan',
    component: FaceScan
  },
  {
    path: '/QRScan', // La nueva ruta para el componente FaceScan
    name: 'QRScan',
    component: QRScan
  },



  // Considera agregar una ruta para listar todos los viajes, si es necesario
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  console.log('Navigating to:', to.fullPath);
  next();
});

router.afterEach((to) => {
  console.log('Navigated to:', to.fullPath);
});

export default router;
